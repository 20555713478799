<template>
  <v-card
    class="joboffer-box joboffer-box--tile"
    elevation="0"
    @click="navigateToJob"
  >
    <v-img :aspect-ratio="16/7" :src="imageUrl"></v-img>

    <v-card-title>
      <span v-if="isFieldEnabled('job-code')">
        {{ joboffer.jobCode }} &mdash;
      </span>
      {{ localize(joboffer.name) }}
    </v-card-title>
    <v-card-text>
      <div class="d-flex flex-wrap text-sm">
        <item-field
          v-for="field in jobDataFields"
          :key="`job-item_${field}`"
          :joboffer="joboffer"
          :field="field"
        ></item-field>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

import itemField from './joboffer-item-fields/item-field.vue';

export default {
  props: {
    joboffer: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      showExternalAdvert: 'showExternalAdvert',
      jobDataFields: 'jobDataFields'
    }),

    imageUrl() {
      if (this.joboffer.headerImages && this.joboffer.headerImages.length > 0) {
        return this.joboffer.headerImages[0].thumbnailUrl;
      }

      return '';
    }
  },

  methods: {
    navigateToJob() {
      if (this.showExternalAdvert) {
        window.open(this.joboffer.advertUrl, '_blank');
      } else {
        this.$router.push({
          name: 'jobDetails',
          params: {
            jobId: this.joboffer.jobofferId,
            jobName: 'joboffer-name'
          }
        });
      }
    },

    isFieldEnabled(fieldName) {
      return this.jobDataFields.includes(fieldName);
    }
  },

  components: {
    itemField
  }
};
</script>

<style scoped>
.joboffer-box:hover {
  cursor: pointer;
}
</style>
